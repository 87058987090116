import * as React from "react";
import classNames from "classnames";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { StaticImage } from "gatsby-plugin-image";
import BtnFilled from "./reusable/BtnFilled";
import Container from "./Layout/Container";
import Slider from "react-slick";
// markup

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Customers = ({ className = "" }) => {
  const breakpoints = useBreakpoint();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    infinite: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 2,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className={classNames("clients-carousel", className)}>
      <Slider {...settings}>
        <div className="item">
          <figure>
            <StaticImage
              src="./../images/home/clients/falabella.png"
              alt="Falabella"
              loading="lazy"
              placeholder="blurred"
              layout="fullWidth"
            />
          </figure>
        </div>
        <div className="item">
          <figure>
            <StaticImage
              src="./../images/home/clients/lowes.png"
              alt="Falabella"
              loading="lazy"
              placeholder="blurred"
              layout="fullWidth"
            />
          </figure>
        </div>
        {/*
        <div className="item">
          <figure>
            <StaticImage
              src="./../images/home/clients/nasdaq.png"
              alt="Falabella"
              loading="lazy"
              placeholder="blurred"
              layout="fullWidth"
            />
          </figure>
        </div>
        */}

        <div className="item">
          <figure>
            <StaticImage
              src="./../images/home/clients/tata-cliq.png"
              alt="Tata Cliq"
              loading="lazy"
              placeholder="blurred"
              layout="fullWidth"
            />
          </figure>
        </div>

        <div className="item">
          <figure>
            <StaticImage
              src="./../images/home/clients/aditya-birla.png"
              alt="Aditya Birla"
              loading="lazy"
              placeholder="blurred"
              layout="fullWidth"
            />
          </figure>
        </div>
        {/*
        <div className="item">
          <figure>
            <StaticImage
              src="./../images/home/clients/super-robotics.png"
              alt="Falabella"
              loading="lazy"
              placeholder="blurred"
              layout="fullWidth"
            />
          </figure>
        </div>
      */}
        <div className="item">
          <figure>
            <StaticImage
              src="./../images/home/clients/safe-security.png"
              alt="Falabella"
              loading="lazy"
              placeholder="blurred"
              layout="fullWidth"
            />
          </figure>
        </div>
        <div className="item">
          <figure>
            <StaticImage
              src="./../images/home/clients/aitomatic.png"
              alt="Falabella"
              loading="lazy"
              placeholder="blurred"
              layout="fullWidth"
            />
          </figure>
        </div>
        <div className="item">
          <figure>
            <StaticImage
              src="./../images/home/clients/covalency.png"
              alt="Falabella"
              loading="lazy"
              placeholder="blurred"
              layout="fullWidth"
            />
          </figure>
        </div>
        <div className="item">
          <figure>
            <StaticImage
              src="./../images/home/clients/clover-bay.png"
              alt="Falabella"
              loading="lazy"
              placeholder="blurred"
              layout="fullWidth"
            />
          </figure>
        </div>
      </Slider>
    </div>
  );
};

export default Customers;
